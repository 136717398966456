import { Base } from '#build/components';
import { BaseInput } from '~/form-kit/BaseInput';

export const InputPassword = {
  inputClass: BaseInput.inputClass,
  labelClass: BaseInput.labelClass,
  messagesClass: BaseInput.messagesClass,
  suffixClass: BaseInput.suffixIconClass,
  validationPassword: 'required',
  validationNewPassword: 'required|?length:8',
  validationNewPasswordMessages: { length: 'Votre nouveau mot de passe doit contenir au moins 8 caractères.' },
  validationNewPasswordConfirm: 'required|confirm',
  validationNewPasswordConfirmMessages: { confirm: 'Les mots de passe ne sont pas identiques.' }
};
